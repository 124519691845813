<template>
  <div
    class="
      layout-fixed-header-section
      d-lg-flex d-block
      justify-content-between
    "
  >
    <div class="header-label col-lg-9 col-12">
      <!-- <div>{{ this.hostname }}</div> -->
    </div>
    <div class="col-lg-3 col-12 text-end d-flex justify-content-end">
      <button
        type="button"
        class="btn position-relative d-flex align-items-center"
      >
        <span class="custom-profile-notification">5</span>
        <i class="pi pi-bell custom-text-primary" aria-hidden="true"></i>
      </button>
      <div class="profile-vertical-devider"></div>
      <div class="profile-signout-dropdown-outer">
        <div class="dropdown">
          <button
            class="
              btn btn-secondary
              dropdown-toggle
              switch-branch-btn
              text-truncate
              d-inline-block
            "
            type="button"
            id="switchdealerbranch"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img
                v-if="this.user_singal_image == null || this.user_singal_image == ''"
                  src="/assets/images/profile.svg"
                  class="profile-header-avatar rounded-circle"
                  alt=""
                  width="30"
                  height="30"
                />
                <img
                v-if="this.user_singal_image != null && this.user_singal_image != ''"
                  :src="user_image"
                  class="profile-header-avatar rounded-circle"
                  alt=""
                  width="30"
                  height="30"
                />
              </div>
              <div class="flex-grow-1 ms-2 text-capitalize"> {{ user_name }} </div>
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="switchdealerbranch">
            <li>
              <router-link class="dropdown-item" to="/myprofile"
                ><i class="pi pi-user me-2"></i>My Profile</router-link
              >
            </li>
            <li>

                <span class="dropdown-item cursor-pointer" @click="logout"
                ><i class="pi pi-sign-out me-2"   ></i>Sign Out</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../service/ApiService";

export default {
  data() {
    return {
      user_name: "",
      user_image: "",
      localstorageimgpath: '',
      client_info: '',
      user_singal_image: "",
      user_info: '',
      hostname: '',
    };
  },

  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
            var localdata = JSON.parse(localStorage.user);
            this.user_name = localdata.ad9;
            this.user_singal_image = localdata.ad22;
            this.client_info = JSON.parse(localStorage.client_info);
            this.user_info = JSON.parse(localStorage.user);
            this.hostname = this.user_info.ad3;
            this.localstorageimgpath = this.client_info.maa24;
            this.user_image = "https://storage.googleapis.com/"+ this.localstorageimgpath +'/host/userprofile/'+ this.user_singal_image;
  },
  methods: {
    logout() {
      this.ApiService.logout().then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.profile-header-avatar {
  background: #b3bae1 0% 0% no-repeat padding-box;
  padding: 2px;
}
</style>
<template>
  <div class="container-fluid" v-if="$route.meta.requiresAuth" @click="checkSessionTimeout">
    <!-- Start for admin side tabs -->
    <div class="row" v-if="userType == 1">
      <AdminSidebarTabs />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
          <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{ new
            Date().getFullYear() }} All rights reserved by Samruddh Bharat Tech Private Limited</div>
          <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
        </div>
      </main>
    </div>
    <!-- End for admin side tabs -->
    <!-- Start for host side tabs -->
    <div class="row" v-if="userType == 2">
      <HostSidebarTabs />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
          <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{ new
            Date().getFullYear() }} All rights reserved by Samruddh Bharat Tech Private Limited</div>
          <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
        </div>
      </main>
    </div>
    <!-- End for host side tabs -->
     <!-- Start for police side tabs -->
     <div class="row" v-if="userType == 3">
      <PoliceSidebarTabs />
      <main class="col-md-9 ms-sm-auto col-lg-10 layout-content-outer">
        <Header />
        <div class="layout-content-inner">
          <router-view />
        </div>
        <div class="footer-bg">
          <div class="footer-bold">Copyright <i class="far fa-copyright" aria-hidden="true"></i> {{ new
            Date().getFullYear() }} All rights reserved by Samruddh Bharat Tech Private Limited</div>
          <!-- <div class="footer-small">Concept Inspiration: Visionary Leader Shri Raj Thakare (श्री. राजसाहेब ठाकरे)</div> -->
        </div>
      </main>
    </div>
     <!-- End for police side tabs -->
  </div>
  <div class="container-fluid" v-if="!$route.meta.requiresAuth">
    <router-view />
  </div>

  <div class="modal-mask" v-if="showReloginPopup">
    <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content modal-outer" v-if="showReloginPopup">
        <div class="row g-2">
          <div class="col-lg-7 col-md-7 col-12">
            <div class="p-3 text-center custom-img-outer">
              <img src="/assets/images/login-banner.jpg" alt="" height="310" />
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-12">
            <div class="p-3 border custom-card-outer h-100">
              <div class="text-center">
                <div class="mb-3">
                  <img src="/assets/images/meraneta-logo-black.png" class="text-center" alt="" height="50" />
                </div>
                <div class="mb-3 custom-user-name text-capitalize">
                  {{ userfullname }}
                </div>
                <div class="horizontal-line mb-3"></div>
                <div class="mb-4 custom-user-content text-secondary">
                  <div>It's been 30 minutes you were away from</div>
                  <div>Visitor Connect</div>
                  <div>Kindly Re-enter
                    your password to Resume</div>
                </div>
                <div class="col-12">
                  <div class="custom-form-group">
                    <div class="input-group">
                      <input class="form-control custom-input-passward" placeholder="Enter Password" aria-label="Password"
                        v-model="password" :type="passwordVisibility" maxlength="12" aria-describedby="formPasswordInput"
                        autocomplete="off" tabindex="0" />
                      <span class="input-group-text" id="formPasswordInput">
                        <button @click="showPasswordType()" v-if="passwordVisibility == 'password'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye-slash"></i>
                        </button>
                        <button @click="hidePasswordType()" v-if="passwordVisibility == 'text'"
                          class="btn btn-link login-password-type">
                          <i class="pi pi-eye"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.password.$error">
                      {{ v$.password.$errors[0].$message }}
                    </div>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-lg-12 col-md-12 col-12">
                    <button type="button" class="btn modal-step-next-btns" @click="login()" :disabled="showloader"
                      tabindex="1">
                      <span v-if="!showloader">Submit</span>
                      <div class="
                          spinner-border
                          text-light
                          custom-spinner-loader-btn
                        " v-if="showloader"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PoliceSidebarTabs from "./components/PoliceSidebarTabs.vue";
import HostSidebarTabs from "./components/HostSidebarTabs.vue";
import AdminSidebarTabs from "./components/AdminSidebarTabs.vue";
import Header from "./components/Header.vue";
//import moment from "moment";
import ApiService from "./service/ApiService";
import { required, helpers, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
export default {
  components: {
    PoliceSidebarTabs:PoliceSidebarTabs,
    HostSidebarTabs: HostSidebarTabs,
    AdminSidebarTabs: AdminSidebarTabs,
    Header: Header,
  },
  data() {
    return {
      v$: useValidate(),
      passwordVisibility: "password",
      showloader: false,
      showReloginPopup: false,
      username: "",
      password: "",
      userfullname: "",
      userType: ""
    };
  },
  mounted() {
    if (
      localStorage.error_toaster_display &&
      localStorage.error_toaster_display == 1
    ) {
      this.$toast.open({
        message: localStorage.logout_reason,
        type: "error",
        duration: 3000,
        position: "top-right",
      });
      localStorage.clear();
    }
    if (localStorage.user) {
      var localdata = JSON.parse(localStorage.user);
      this.userType = localdata.ad23
    }
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  watch: {
    password() {
      this.v$.$validate();
    },
  },

  validations() {
    return {
      password: {
        required: helpers.withMessage("Please enter password", required),
        maxLength: helpers.withMessage(
          "Password should be max 12 length",
          maxLength(12)
        ),
      },
    };
  },

  methods: {
    showPasswordType() {
      this.passwordVisibility = "text";
    },

    hidePasswordType() {
      this.passwordVisibility = "password";
    },

    checkSessionTimeout() {
      // if (
      //   localStorage.last_login_time &&
      //   localStorage.last_login_time != null
      // ) {
      //   let now = new Date().toUTCString();
      //   var diff =
      //     (moment.utc(now) - moment.utc(localStorage.last_login_time)) / 1000;
      //   diff /= 60;
      //   diff = Math.abs(Math.round(diff));
      //   if (diff >= 30) {
      //     localStorage["logoutUser"] = 1;
      //     localStorage["sessionStandBy"] = 0;
      //     this.$toast.open({
      //       message:
      //         "Your session expired as you were inactive for more than 30 minutes. Please login again to use ",
      //       type: "error",
      //       duration: 3000,
      //       position: "top-right",
      //     });
      //     setTimeout(() => {
      //       this.logoutOnSessionTimeOut();
      //     }, 1000);
      //   } else {
      //     var currentTime = new Date().toUTCString();
      //     localStorage["last_login_time"] = currentTime;
      //   }
      // }
    },
    logoutOnSessionTimeOut() {
      localStorage["sessionStandBy"] = 0;
      this.ApiService.logoutonsessiontimeout({ logout_reason: 3 }).then(
        (data) => {
          if (data.status == 200) {
            var userData = localStorage.user;
            this.username = userData.ak5;
            this.userfullname = userData.user_short_name;
            localStorage.clear();
            localStorage["logoutUser"] = 1;
            this.showReloginPopup = true;
          }
        }
      );
    },
    login() {
      this.v$.$validate();
      let fields = {};
      fields["login_id"] = this.username;
      fields["login_password"] = this.password;
      if (!this.v$.$error) {
        this.showloader = true;
        this.ApiService.login(fields).then((items) => {
          if (items.status === 200) {
            this.showReloginPopup = false;
            this.showloader = false;
            var currentTime = new Date().toUTCString();
            localStorage["last_login_time"] = currentTime;
            localStorage["logoutUser"] = 0;
            localStorage["sessionStandBy"] = 0;

            localStorage["user"] = JSON.stringify(items.data.user_data);
            localStorage["permission_fks"] = JSON.stringify(items.data.permission_fks);
            localStorage["user_reference"] = items.data.user_reference;
            localStorage["client_info"] = JSON.stringify(
              items.data.client_info
            );


            // this.$toast.open({
            //   message: items.message,
            //   type: "success",
            //   duration: 3000,
            //   position: "top-right",
            // });
            location.reload();
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
          } else {
            this.showloader = false;
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
.modal-content.modal-outer {
  padding: 10px;
}

.horizontal-line {
  border: 1px solid #ebeef5;
  opacity: 1;
}

.custom-user-name {
  letter-spacing: 0.32px;
  color: #2b2e38;
  opacity: 1;
  font-size: 20px;
  font-family: "AcuminPro-Regular";
}

.custom-user-content {
  letter-spacing: 0.26px;
  color: #4a5463;
  opacity: 1;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
}

.modal-dialog-scrollable .modal-content {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #e9e9e4;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-form-group .custom-error {
  color: #de2525;
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  text-align: left;
  letter-spacing: 0.52px;
  line-height: 20px;
  position: absolute;
}

.custom-form-group {
  margin-bottom: 40px;
}

.custom-logo {
  background: #2a2a45 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #000;
  padding: 12px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 50px;
}

.modal-step-next-btns.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  min-width: 150px;
  width: 100%;
}

.modal-step-next-btns.btn:focus,
.modal-step-next-btns.btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.custom-img-outer {
  height: 100%;
  background-color: #ababab;
}

.custom-card-outer {
  background-color: #ffffff;
}
</style>
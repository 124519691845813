<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-fixed-outer">
        <header class="navbar navbar-dark bg-dark flex-md-nowrap">
          <div class="navbar-brand me-0 d-flex align-items-center justify-content-center">
            <!-- <div class="navbar-brand-image d-lg-flex d-block justify-content-center pb-2"> -->
              <!-- <span class="py-0 px-2"> -->
                <!-- <img src="/assets/images/company-logo.svg" class="rounded-circle dashboard-img-border d-block"
                  alt="client-logo" width="40" height="40" /> -->
                <!-- <img src="/assets/images/logo11.png"
                class="img-fluid" alt="party-head-img" height="40" /> -->
              <!-- </span> -->
              <!-- <span>
                <img v-if="this.client_info.maa6 != null && this.client_info.maa6 != ''" :src="client_logo"
                class="rounded-circle dashboard-img-border d-block" alt="client-logo" width="40" height="40" />
              </span> -->
          
            <div class="text-capitalize">
              <div class="navbar-brand-label-welcome">Welcome,</div>
              <div class="navbar-brand-host-label">{{ this.host_name }}</div>
            </div>
          </div>
          <!-- </div> -->
          <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </header>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <div v-for="(menuItem, headIndex) in sidebarMenu" :key="headIndex">
            <ul class="nav flex-column" :id="'header-' + headIndex">
              <li class="nav-item">
                <router-link v-if="menuItem.menu" class="nav-link" :to="menuItem.link || ''"><img :src="menuItem.img"
                    class="navlink-tab-icon" alt="menu" width="16" />{{ menuItem.menu }}</router-link>
                <button v-else-if="menuItem.menuHeader" class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse" :data-bs-target="`#${menuItem.menuHeader}`" aria-expanded="false">
                  <img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{ menuItem.menuHeader }}
                </button>
                <div v-if="menuItem.sidebarSubMenu" class="collapse" :data-bs-parent="`#header-${headIndex}`"
                  :id="menuItem.menuHeader">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-for="(subMenu, index) in menuItem.sidebarSubMenu" :key="index">
                      <router-link class="nav-link" :to="subMenu.link">{{ subMenu.submenu }}</router-link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-sub-inner p-2">
          <div class="logo-outer">
            <img src="/assets/images/Visitor-Connect-Logo-New.png" class="img-fluid" alt="Visitor Connect" width="90" />
          </div>
        </div>
        <div class="copyright-sub-inner p-2">
          <div class="logo-outer">
            <img src="/assets/images/goa-electronics.png" class="img-fluid" alt="Goa Electronics Limited" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      host_name: "",
      client_info: "",
      client_logo: "",
      baseUrl: window.location.origin,
      permission_fks: localStorage.permission_fks,
      user_info: '',
      sidebarMenu: [
        {
          menu: "Dashboard",
          link: "/dashboard",
          img: "/assets/images/icon/overview-tab-icon.svg",
        },
        {
          menu: "Branches list",
          link: "/branchlist",
          img: "/assets/images/icon/company-menu-icon.svg",
        },
        {
          menuHeader: "Users",
          img: "/assets/images/icon/customers-tab-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Add User",
              link: "/user/add"
            },
            {
              submenu: "List Users",
              link: "/user/listing"
            },
            {
              submenu: "Permission Sets",
              link: "/user/permissionsets"
            }

          ]
        },
        {
          menuHeader: "Guests",
          img: "/assets/images/icon/visitors-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Add Guest",
              link: "/guests/add"
            },
            {
              submenu: "Total Guests",
              link: "/guests/totalguest"
            },
            {
              submenu: "Add Reports",
              link: "/guests/addreport"
            },
            {
              submenu: "List Reports",
              link: "/guests/reports"
            },

          ]
        },
        {
          menuHeader: "Billing",
          img: "/assets/images/icon/billing-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Dashboard",
              link: "/billing/dashboard"
            },
            {
              submenu: "Tax Invoices",
              link: "/billing/invoice"
            },
            {
              submenu: "Account Statement",
              link: "/billing/statement"
            },
            {
              submenu: "Payment Receipts",
              link: "/billing/receipt"
            },
            {
              submenu: "Company Details",
              link: "/billing/companydetails"
            },

          ]
        },
        {
          menu: "Support Tickets",
          // menuBottom: "Support Tickets",
          link: "/support/new",
          img: "/assets/images/icon/support-tab-icon.svg",
        },
        {
          menuHeader: "System Logs",
          img: "/assets/images/icon/system-logs-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Web Bot Logs",
              link: "/user/add"
            }
          ]
        },
      ]
    };
  },
  mounted() {
    // if (this.permission_fks) {
    //   this.permission_fks = this.permission_fks.split(',');
    // } else {
    //   this.permission_fks = [];
    // }
    this.user_info = JSON.parse(localStorage.user);
    this.host_name = this.user_info.ad3;
    this.client_info = JSON.parse(localStorage.client_info);
    if (this.baseUrl == 'http://localhost:8080') {
      this.client_path = this.client_info.maa24;
    } else {
      this.client_path = localStorage.masterbucketname;
    }
    this.client_logo = "https://storage.googleapis.com/" + this.client_path + "/hostCompany/" + this.client_info.maa6;
  },
};
</script>



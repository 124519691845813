import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    exact: true,
    component: () => import("./pages/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: "/login",
    name: "Login",
    exact: true,
    component: () => import("./pages/Login.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    exact: true,
    component: () => import("./pages/ForgotPassword.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: true,
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/Dashboard.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/Dashboard.vue");
      }
      else if (window.userType == 3) {
        return import("./pages/policemodule/Dashboard.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/add",
    name: "AddGuest",
    component: () => import("./pages/hostmodule/guests/AddGuest.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/totalguest",
    name: "ListGuests",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/guests/ListGuests.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/guests/ListGuests.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/reports",
    name: "GuestsReport",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/guests/GuestsReport.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/guests/GuestsReport.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/addreport",
    name: "AddGuestsReport",
    component: () =>  import("./pages/hostmodule/guests/AddGuestReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/guests/reportedby",
    name: "GuestsReportedby",
    component: () => import("./pages/adminmodule/guests/GuestReportedByHostOrPolice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/dashboard",
    name: "BillingDashboard",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/billing/Dashboard.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/HostBilling/Dashboard.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/billing/dashboard",
    name: "BillingDashboardAdmin",
    component: () =>
      import("./pages/adminmodule/SuperAdminBilling/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/invoice",
    name: "BillingTaxInvoice",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/billing/TaxInvoice.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/HostBilling/TaxInvoice.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/billing/invoice",
    name: "BillingTaxInvoiceAdmin",
    component: () =>
      import("./pages/adminmodule/SuperAdminBilling/TaxInvoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/statement",
    name: "BillingAccountStatement",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/billing/AccountStatement.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/HostBilling/AccountStatement.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/billing/statement",
    name: "BillingAccountStatementAdmin",
    component: () =>
      import("./pages/adminmodule/SuperAdminBilling/AccountStatement.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/receipt",
    name: "BillingPaymentReceipt",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/billing/PaymentReceipt.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/HostBilling/PaymentReceipt.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/billing/receipt",
    name: "BillingPaymentReceiptAdmin",
    component: () =>
      import("./pages/adminmodule/SuperAdminBilling/PaymentReceipt.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/changeplanrequest",
    name: "ChangePlanRequest",
    component: () => import("./pages/hostmodule/billing/ChangePlanRequest.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/accountstatement",
    name: "AdvanceAccountStatement",
    component: () => {
      import("./pages/hostmodule/billing/AdvanceAccountStatement.vue");
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/companydetails",
    name: "CompanyDetails",
    component: () =>
      import("./pages/hostmodule/billing/CompanyDetails.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/admin/billing/companydetails",
    name: "CompanyDetailsAdmin",
    component: () =>
      import("./pages/adminmodule/SuperAdminBilling/CompanyDetails.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/livebalances",
    name: "LiveBalances",
    component: () => import("./pages/adminmodule/HostBilling/LiveBalances.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // Super Admin Routes//
  {
    path: "/user/add",
    name: "NewUser",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/Users/AddUser.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/Users/AddUser.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/listing",
    name: "UserListing",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/Users/ListUsers.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/Users/ListUsers.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/user/permissionsets",
    name: "UserRole",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/Users/UsersRole.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/Users/UsersRole.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/branchlist",
    name: "BranchList",
    component: () => import("./pages/hostmodule/BranchList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/new",
    name: "NewTicket",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/support/NewTicket.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/support/NewTicket.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/open",
    name: "OpenTicket",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/support/OpenTicket.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/support/OpenTicket.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/closed",
    name: "ClosedTicket",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/support/ClosedTicket.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/support/ClosedTicket.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/support/cancelled",
    name: "CancelledTicket",
    component: () => {
      if (window.userType == 2) {
        return import("./pages/hostmodule/support/CancelledTicket.vue");
      } else if (window.userType == 1) {
        return import("./pages/adminmodule/support/CancelledTicket.vue");
      }
    },
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/myprofile",
    name: "MyProfile",
    component: () => import("./pages/MyProfile.vue"),

    meta: {
      requiresAuth: true,
    },
  },
  // ??????????????
  {
    path: "/campaign",
    name: "CampaignList",
    component: () => import("./pages/adminmodule/campaign/CampaignList.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaign/logs/:campaignId?",
    name: "CampaignLogs",
    component: () => import("./pages/adminmodule/campaign/CampaignLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/campaign/report/:campaignId?",
    name: "CampaignReports",
    component: () => import("./pages/adminmodule/campaign/CampaignReport.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/sms",
    name: "SMSLogs",
    component: () => import("./pages/adminmodule/systemlogs/SmsLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/whatsapplogs",
    name: "WhatsAppLogs",
    component: () => import("./pages/adminmodule/systemlogs/WhatsAppLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/webbotlogs",
    name: "WebBotLogs",
    component: () => import("./pages/adminmodule/systemlogs/WebBotLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/email",
    name: "EmailLogs",
    component: () => import("./pages/adminmodule/systemlogs/EmailLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/incoming",
    name: "IncomingLogs",
    component: () => import("./pages/adminmodule/systemlogs/MyIncomingLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/outgoing",
    name: "OutgoingLogs",
    component: () => import("./pages/adminmodule/systemlogs/MyOutgoingLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/driving-licence-logs",
    name: "DrivingLicenceLogs",
    component: () => import("./pages/adminmodule/systemlogs/DrivingLicenceLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/passportlogs",
    name: "PassportLogs",
    component: () => import("./pages/adminmodule/systemlogs/PassportLogs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/aadhar-v1-card",
    name: "AadharV1Logs",
    component: () => import("./pages/adminmodule/systemlogs/AadharV1Logs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs/aadhar-v2-card",
    name: "AadharV2Logs",
    component: () => import("./pages/adminmodule/systemlogs/AadharV2Logs.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephony/virtualnumbers",
    name: "VirtualNumbers",
    component: () => import("./pages/adminmodule/cloudtelephony/VirtualNumbers.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cloudtelephony/custometunes",
    name: "CustomTunes",
    component: () => import("./pages/adminmodule/cloudtelephony/CustomTunes.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/cloudtelephony/virtualnumbers/set/:param1/:param2',
    name: 'VirtualNumberSet',
    component: () => import('./pages/adminmodule/cloudtelephony/VirtualNumberSet.vue'),
    meta: { requiresAuth: true, },
  },
  {
    path: '/cloudtelephony/virtualnumbers/existset/:param1/:param2',
    name: 'VirtualNumberExistSet',
    component: () => import('./pages/adminmodule/cloudtelephony/VirtualNumberExistSet.vue'),
    meta: { requiresAuth: true, },
  },
  //    Setting Routes   //
  {
    path: "/settings/chargessetting",
    name: "ChargesSetting",
    component: () => import("./pages/adminmodule/settings/ChargesSetting.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/guestrecordsetting",
    name: "GuestRecordSetting",
    component: () => import("./pages/adminmodule/settings/GuestRecordSetting.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/hostsetting",
    name: "HostSetting",
    component: () => import("./pages/adminmodule/settings/HostSetting.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/invoice",
    name: "InvoiceSetting",
    component: () => import("./pages/adminmodule/settings/Invoice.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  ////////////////////////////

  // Campaign template routes //
  {
    path: "/campaigntemplate/audiocall",
    name: "AudioCallCampaignListing",
    component: () => import("./pages/adminmodule/campaigntemplatelist/AudioCallCampaignListing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/campaigntemplate/email",
  //   name: "EmailCampaignListing",
  //   component: () => import("./pages/adminmodule/campaigntemplatelist/EmailCampaignListing.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/campaigntemplate/rcsimessage",
  //   name: "iMessageCampaignListing",
  //   component: () => import("./pages/adminmodule/campaigntemplatelist/iMessageCampaignListing.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/campaigntemplate/Intelligent",
  //   name: "IntelligentCampaignListing",
  //   component: () => import("./pages/adminmodule/campaigntemplatelist/IntelligentCampaignListing.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/campaigntemplate/pushnotification",
    name: "pushNotificationCampaignListing",
    component: () => import("./pages/adminmodule/campaigntemplatelist/PushNotificationCampaignListing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/campaigntemplate/sms",
  //   name: "SMSCampaignListing",
  //   component: () => import("./pages/adminmodule/campaigntemplatelist/SMSCampaignListing.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/campaigntemplate/whatsapp",
    name: "WhatsAppCampaignListing",
    component: () => import("./pages/adminmodule/campaigntemplatelist/WhatsAppCampaignListing.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //// law users ///////
  {
    path: "/lawuser/add",
    name: "NewUserLaw",
    component: () => import("./pages/adminmodule/lawUser/addLawUser.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/lawuser/listing",
    name: "UserListingLaw",
    component: () => import("./pages/adminmodule/lawUser/listLawUser.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  ///////////////////////////////
  //  Host Routes  //

  // {
  //   path: "/hosts/add",
  //   name: "AddHost",
  //   component: () => import("./pages/adminmodule/hosts/AddHost.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/hosts/listing",
    name: "HostListing",
    component: () => import("./pages/adminmodule/hosts/ListHosts.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/hosts/unapproved-listing",
    name: "HostUnApprovedListing",
    component: () => import("./pages/adminmodule/hosts/ListHostUnapproved.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  //   police station routes  //
  {
    path: "/PoliceStations/add",
    name: "NewStations",
    component: () => import("./pages/policemodule/PoliceStations/AddStations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/PoliceStations/listing",
    name: "PoliceStationsListing",
    component: () => import("./pages/policemodule/PoliceStations/ListStations.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chatbot',
    name: 'ChatBotLandingPage',
    exact: true,
    component: () =>
      import('./pages/chatbot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/webbot',
    name: 'WebBot',
    exact: true,
    component: () =>
      import('./pages/WebBot.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  ////////////

  {
    path: "/:pathMatch(.*)",
    name: "notfound",
    component: () => import("./pages/NotFound"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/underprogress",
    name: "underprogress",
    exact: true,
    component: () => import("./pages/UnderProgress.vue"),
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/demo",
    name: "DemoPage",
    component: () => import("./pages/DemoPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (localStorage.user) {
    const localdata = JSON.parse(localStorage.user);
    window.userType = localdata.ad23;
  }
  const authenticatedUser = localStorage.user_reference;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const checkModuleAccess = to.matched.some((record) => record.meta.checkModuleAccess);
  const moduleId = to.meta.moduleId;
  const permission_fks = [1, 11, 12];

  if (requiresAuth && !authenticatedUser) {
    next("/");
  } else {
    if (checkModuleAccess) {
      if (permission_fks.includes(moduleId)) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
});

export default router;

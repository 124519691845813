<template>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
            <div class="custom-form-group">
                <label class="intelligent-campagin-modal-header">Audio Call Campaign</label>
            </div>
        </div>
    </div>
    <div class="campaign-tab-outer-scroll" :style="{ height: '409px' }">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Template Name <span class="text-danger">*</span></label>
                    <input type="text" v-model="templatename" class="form-control" placeholder="Enter Template Name"
                        maxlength="250" autocomplete="off" />
                    <div class="custom-error" v-if="v$.templatename.$error">
                        {{ v$.templatename.$errors[0].$message }}
                    </div>
                    <div class="custom-error" v-if="duplicateTemplateMsg">
                        {{ duplicateTemplateMsg }}
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Select Language<span class="text-danger">*</span></label>
                    <Multiselect v-model="languagevalue" :options="languagelist" :searchable="true" label="label"
                        placeholder="Select Language" class="multiselect-custom text-capitalize" :canClear="true"
                        :closeOnSelect="true" noOptionsText="No Result Found" :object="true" />
                    <div class="custom-error" v-if="v$.languagevalue.$error">
                        {{ v$.languagevalue.$errors[0].$message }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Response Type<span class="text-danger">*</span></label>
                    <div class="custom-group-radio-box-btn">
                        <input type="radio" class="btn-check" v-model="responsetype" name="none-option" id="noresponse"
                            autocomplete="off" @click="clearresponse()" value="0" />
                        <label class="btn custom-radio-box-btn text-capitalize" for="noresponse">No Response</label>
                        <input type="radio" class="btn-check" v-model="responsetype" name="with-option" id="withresponse"
                            autocomplete="off" value="1" />
                        <label class="btn custom-radio-box-btn text-capitalize" for="withresponse">With Response</label>
                    </div>
                    <div class="custom-error" v-if="v$.responsetype.$error">
                        {{ v$.responsetype.$errors[0].$message }}
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="custom-form-group">
                    <label class="form-label">Select Audio<span class="text-danger">*</span></label>
                    <FileUpload mode="basic" accept=".mp3" ref="headeraudioref" class="custom-upload-btn-ultima"
                        v-model="headeraudio" v-on:change="handlerAudioUploader()" />
                    <div class="custom-error" v-if="errortuneaddfile">{{ errortuneaddfile }}</div>
                    <div class="custom-error" v-if="v$.headeraudio.$error">
                        {{ v$.headeraudio.$errors[0].$message }}
                    </div>
                </div>
                <!-- <div v-if="exitaddpreviewurl != null">
                    <audio controls controlsList="download" style="width: 40%;">
                        <source :src="exitaddpreviewurl.objectURL" type="audio/mpeg" />
                    </audio>
                </div> -->
            </div>
        </div>
        <!--  -->
        <div class="row" v-if="responsetype == 1">
            <div class="col-lg-12 col-md-12 col-12 mb-3">
                <div class="form-box-outer">
                    <!-- Response One  -->
                    <div class="custom-form-group">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumberone" id="responsenumberone"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseOneFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevalueone" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevalueone.$error">
                                    {{ v$.responsevalueone.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatusone" :true-value="dynamicTrueActiveValue"
                                    :false-value="dynamicFalseInactiveValue" class="mt-1"
                                    @change="changeStatusOne(responsestatusone)" />
                                <div class="custom-error" v-if="v$.responsestatusone.$error">
                                    {{ v$.responsestatusone.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" />
                    <!-- Response Two  -->
                    <div class="custom-form-group">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumbertwo" id="responsenumbertwo"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseTwoFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevaluetwo" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevaluetwo.$error">
                                    {{ v$.responsevaluetwo.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatustwo" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatustwo.$error">
                                    {{ v$.responsestatustwo.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="thirdrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreThirdRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="thirdrowshowstatus" />
                    <!-- Response Three  -->
                    <div class="custom-form-group" v-if="thirdrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumberthree" id="responsenumberthree"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseThreeFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevaluethree" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevaluethree.$error">
                                    {{ v$.responsevaluethree.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatusthree" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatusthree.$error">
                                    {{ v$.responsestatusthree.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="fourthrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreFourthRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="fourthrowshowstatus" />
                    <!-- Response Four  -->
                    <div class="custom-form-group" v-if="fourthrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumberfour" id="responsenumberfour"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseFourFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevaluefour" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevaluefour.$error">
                                    {{ v$.responsevaluefour.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatusfour" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatusfour.$error">
                                    {{ v$.responsestatusfour.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="fifthrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreFifthRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="fifthrowshowstatus" />
                    <!-- Response Five  -->
                    <div class="custom-form-group" v-if="fifthrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumberfive" id="responsenumberfive"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseFiveFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevaluefive" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevaluefive.$error">
                                    {{ v$.responsevaluefive.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatusfive" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatusfive.$error">
                                    {{ v$.responsestatusfive.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="sixthrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreSixthRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="sixthrowshowstatus" />
                    <!-- Response Six  -->
                    <div class="custom-form-group" v-if="sixthrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumbersix" id="responsenumbersix"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseSixFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevaluesix" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevaluesix.$error">
                                    {{ v$.responsevaluesix.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatussix" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatussix.$error">
                                    {{ v$.responsestatussix.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="seventhrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreSeventhRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="seventhrowshowstatus" />
                    <!-- Response Seven  -->
                    <div class="custom-form-group" v-if="seventhrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumberseven" id="responsenumberseven"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseSevenFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevalueseven" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevalueseven.$error">
                                    {{ v$.responsevalueseven.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatusseven" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatusseven.$error">
                                    {{ v$.responsestatusseven.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="eighthrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreEighthRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="eighthrowshowstatus" />
                    <!-- Response Eight  -->
                    <div class="custom-form-group" v-if="eighthrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumbereight" id="responsenumbereight"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseEightFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevalueeight" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevalueeight.$error">
                                    {{ v$.responsevalueeight.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatuseight" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatuseight.$error">
                                    {{ v$.responsestatuseight.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="custom-form-group" v-if="ninethrowbtnstatus">
                        <div class="row text-start">
                            <div class="col-lg-12 col-md-12 col-12">
                                <button type="button" @click="addMoreNinethRow()" class="btn custom-view-detail-btn"><i
                                        class="pi pi-plus me-1" style="font-size: 12px;"></i>Add More Response</button>
                            </div>
                        </div>
                    </div>
                    <Divider class="mb-3" v-if="ninethrowshowstatus" />
                    <!-- Response Nine  -->
                    <div class="custom-form-group" v-if="ninethrowshowstatus">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <div class="input-group">
                                    <input type="text" v-model="responsenumbernine" id="responsenumbernine"
                                        class="form-control text-center fw-bold" disabled maxlength="1"
                                        @keypress="onlyResponseNineFormat" autocomplete="off" style="max-width: 12%;" />
                                    <span class="input-group-text" id="formPasswordInput" style="width: 88%;">
                                        <input type="text" v-model="responsevaluenine" class="form-control"
                                            placeholder="Enter Response Value" maxlength="30"
                                            @keypress="isLetterWithSpace($event)" autocomplete="off" />
                                    </span>
                                </div>
                                <div class="custom-error" v-if="v$.responsevaluenine.$error">
                                    {{ v$.responsevaluenine.$errors[0].$message }}
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-12">
                                <InputSwitch v-model="responsestatusnine" class="mt-1" />
                                <div class="custom-error" v-if="v$.responsestatusnine.$error">
                                    {{ v$.responsestatusnine.$errors[0].$message }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-12">
                <div class="custom-form-group text-center">
                    <button type="button" class="btn modal-bulk-next-btn" @click="audiosubmitbtn()"
                        :disabled="audiogbtnloader">
                        <span v-if="!audiogbtnloader">Submit</span>
                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                            v-if="audiogbtnloader">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ApiService from "../../service/ApiService";
import useValidate from "@vuelidate/core";
import { required, requiredIf, helpers } from "@vuelidate/validators";
export default {
    data() {
        return {
            v$: useValidate(),
            audiogbtnloader: false,
            templatename: '',
            languagevalue: '',
            responsetype: '',
            headeraudio: null,
            duplicateTemplateMsg: '',
            errortuneaddfile: '',
            exitaddpreviewurl: null,
            languagelist: [],

            responsenumberone: 1,
            responsevalueone: '',
            responsestatusone: '',

            responsenumbertwo: 2,
            responsevaluetwo: '',
            responsestatustwo: '',

            responsenumberthree: 3,
            responsevaluethree: '',
            responsestatusthree: '',

            responsenumberfour: 4,
            responsevaluefour: '',
            responsestatusfour: '',

            responsenumberfive: 5,
            responsevaluefive: '',
            responsestatusfive: '',

            responsenumbersix: 6,
            responsevaluesix: '',
            responsestatussix: '',

            responsenumberseven: 7,
            responsevalueseven: '',
            responsestatusseven: '',

            responsenumbereight: 8,
            responsevalueeight: '',
            responsestatuseight: '',

            responsenumbernine: 9,
            responsevaluenine: '',
            responsestatusnine: '',

            switchuncheck: false,
            dynamicTrueActiveValue: 1,
            dynamicFalseInactiveValue: 0,

            thirdrowshowstatus: false,
            thirdrowbtnstatus: true,

            fourthrowshowstatus: false,
            fourthrowbtnstatus: false,

            fifthrowshowstatus: false,
            fifthrowbtnstatus: false,

            sixthrowshowstatus: false,
            sixthrowbtnstatus: false,

            seventhrowshowstatus: false,
            seventhrowbtnstatus: false,

            eighthrowshowstatus: false,
            eighthrowbtnstatus: false,

            ninethrowshowstatus: false,
            ninethrowbtnstatus: false,
        };
    },
    validations() {
        return {
            templatename: {
                required: helpers.withMessage("Please enter template name", required),
            },
            languagevalue: {
                required: helpers.withMessage("Please select language", required),
            },
            responsetype: {
                required: helpers.withMessage("Please select response type", required),
            },
            headeraudio: {
                required: helpers.withMessage("Please upload audio file", required),
            },
            // response one
            responsevalueone: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            responsestatusone: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            // response two
            responsevaluetwo: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            responsestatustwo: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsetype == 1
                    )
                ),
            },
            // response three
            responsevaluethree: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusthree == true
                    )
                ),
            },
            responsestatusthree: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluethree != ''
                    )
                ),
            },
            // response four
            responsevaluefour: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusfour == true
                    )
                ),
            },
            responsestatusfour: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluefour != ''
                    )
                ),
            },
            // response five
            responsevaluefive: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusfive == true
                    )
                ),
            },
            responsestatusfive: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluefive != ''
                    )
                ),
            },
            // response six
            responsevaluesix: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatussix == true
                    )
                ),
            },
            responsestatussix: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluesix != ''
                    )
                ),
            },
            // response seven
            responsevalueseven: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusseven == true
                    )
                ),
            },
            responsestatusseven: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevalueseven != ''
                    )
                ),
            },
            // response eight
            responsevalueeight: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatuseight == true
                    )
                ),
            },
            responsestatuseight: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevalueeight != ''
                    )
                ),
            },
            // response nine
            responsevaluenine: {
                requiredIf: helpers.withMessage(
                    "Please enter response value",
                    requiredIf(
                        this.responsestatusnine == true
                    )
                ),
            },
            responsestatusnine: {
                requiredIf: helpers.withMessage(
                    "Please select response status",
                    requiredIf(
                        this.responsevaluenine != ''
                    )
                ),
            },
        };
    },
    ApiService: null,

    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getlanguages();
    },
    methods: {
        addMoreThirdRow() {
            this.thirdrowshowstatus = true;
            this.thirdrowbtnstatus = false;
            this.fourthrowbtnstatus = true;
        },
        addMoreFourthRow() {
            this.fourthrowshowstatus = true;
            this.fourthrowbtnstatus = false;
            this.fifthrowbtnstatus = true;
        },

        addMoreFifthRow() {
            this.fifthrowshowstatus = true;
            this.fifthrowbtnstatus = false;
            this.sixthrowbtnstatus = true;
        },

        addMoreSixthRow() {
            this.sixthrowshowstatus = true;
            this.sixthrowbtnstatus = false;
            this.seventhrowbtnstatus = true;
        },

        addMoreSeventhRow() {
            this.seventhrowshowstatus = true;
            this.seventhrowbtnstatus = false;
            this.eighthrowbtnstatus = true;
        },

        addMoreEighthRow() {
            this.eighthrowshowstatus = true;
            this.eighthrowbtnstatus = false;
            this.ninethrowbtnstatus = true;
        },

        addMoreNinethRow() {
            this.ninethrowshowstatus = true;
            this.ninethrowbtnstatus = false;
        },
        getlanguages(e) {
            this.ApiService.getlanguages(e).then((data) => {
                if (data.status == 200) {
                    this.languagelist = data.data;
                } else {
                    this.languagelist = [];
                }
            });
        },
        audiosubmitbtn() {
            this.v$.$validate();
            var formData = new FormData();
            formData.append("cl2", this.templatename);
            if (this.languagevalue) {
                formData.append('cl7', this.languagevalue.value);
            }
            formData.append("cl8", this.responsetype);
            if (this.headeraudio != null) {
                formData.append("media_file", this.headeraudio);
            }
            if (this.responsetype == 1) {
                formData.append("cl9", this.responsenumberone);
                formData.append("cl10", this.responsevalueone);
                if (this.responsestatusone == true) {
                    formData.append("cl11", 1);
                } else {
                    formData.append("cl11", 0);
                }

                formData.append("cl12", this.responsenumbertwo);
                formData.append("cl13", this.responsevaluetwo);
                if (this.responsestatustwo == true) {
                    formData.append("cl14", 1);
                } else {
                    formData.append("cl14", 0);
                }

                formData.append("cl15", this.responsenumberthree);
                formData.append("cl16", this.responsevaluethree);
                if (this.responsestatusthree == true) {
                    formData.append("cl17", 1);
                } else {
                    formData.append("cl17", 0);
                }

                formData.append("cl18", this.responsenumberfour);
                formData.append("cl19", this.responsevaluefour);
                if (this.responsestatusfour == true) {
                    formData.append("cl20", 1);
                } else {
                    formData.append("cl20", 0);
                }

                formData.append("cl21", this.responsenumberfive);
                formData.append("cl22", this.responsevaluefive);
                if (this.responsestatusfive == true) {
                    formData.append("cl23", 1);
                } else {
                    formData.append("cl23", 0);
                }

                formData.append("cl24", this.responsenumbersix);
                formData.append("cl25", this.responsevaluesix);
                if (this.responsestatussix == true) {
                    formData.append("cl26", 1);
                } else {
                    formData.append("cl26", 0);
                }

                formData.append("cl27", this.responsenumberseven);
                formData.append("cl28", this.responsevalueseven);
                if (this.responsestatusseven == true) {
                    formData.append("cl29", 1);
                } else {
                    formData.append("cl29", 0);
                }

                formData.append("cl30", this.responsenumbereight);
                formData.append("cl31", this.responsevalueeight);
                if (this.responsestatuseight == true) {
                    formData.append("cl32", 1);
                } else {
                    formData.append("cl32", 0);
                }

                formData.append("cl33", this.responsenumbernine);
                formData.append("cl34", this.responsevaluenine);
                if (this.responsestatusnine == true) {
                    formData.append("cl35", 1);
                } else {
                    formData.append("cl35", 0);
                }
            }
            // for (var pair of formData.entries()) {
            //     console.log(pair[0] + ' - ' + pair[1]);
            // }

            if (!this.v$.$error && !this.errortuneaddfile && !this.duplicateTemplateMsg) {
                this.audiogbtnloader = true;
                this.ApiService.addCallCampaignTemplate(formData).then((data) => {
                    if (data.success == true) {
                        this.audiogbtnloader = false;
                        this.$store.dispatch("addCampaignStatus");
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        setTimeout(() => {
                            this.v$.$reset();
                        }, 0);
                        this.redirectcampaigntemplate();
                    } else {
                        var errorMsg = data.message;
                        this.$toast.open({
                            message: errorMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.audiogbtnloader = false;
                    }
                });
            } else {
                this.$error;
            }
        },
        handlerAudioUploader() {
            var file = this.$refs.headeraudioref.files[0];
            let allowedExtensions = /(\.mp3)$/i;
            // if (file.size > 2000000) {
            //     this.errortuneaddfile = 'File size cannot exceed more than 2MB';
            // } else
            if (!file.name.match(allowedExtensions)) {
                this.errortuneaddfile = 'Invalid file type';
            } else {
                this.errortuneaddfile = '';
                this.headeraudio = this.$refs.headeraudioref.files[0];
                this.exitaddpreviewurl = this.headeraudio;
            }
        },

        checkTemplateName() {
            if ((this.templatename).length > 3 && localStorage.getItem('call_templates') !== null) {
                var templates = JSON.parse(localStorage.getItem('call_templates'));
                for (var i = 0, len = templates.length; i < len; i++) {
                    if ((templates[i]['cl2']).toLowerCase() === (this.templatename).toLowerCase()) {
                        this.duplicateTemplateMsg = 'Template name already exits.';
                        break;
                    } else {
                        this.duplicateTemplateMsg = '';
                    }
                }
            }
        },
        redirectcampaigntemplate() {
            window.location.href = "/campaigntemplate/audiocall";
        },
        isLetterWithSpace(e) {
            let charspace = String.fromCharCode(e.keyCode);
            if (/^[A-Za-z\s]+$/.test(charspace)) return true;
            else e.preventDefault();
        },
        onlyResponseOneFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberone = document.getElementById("responsenumberone");
            if ((responsenumberone.value == '' || responsenumberone.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        onlyResponseTwoFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbertwo = document.getElementById("responsenumbertwo");
            if ((responsenumbertwo.value == '' || responsenumbertwo.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },


        onlyResponseThreeFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberthree = document.getElementById("responsenumberthree");
            if ((responsenumberthree.value == '' || responsenumberthree.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFourFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfour = document.getElementById("responsenumberfour");
            if ((responsenumberfour.value == '' || responsenumberfour.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseFiveFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberfive = document.getElementById("responsenumberfive");
            if ((responsenumberfive.value == '' || responsenumberfive.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseSixFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbersix = document.getElementById("responsenumbersix");
            if ((responsenumbersix.value == '' || responsenumbersix.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseSevenFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumberseven = document.getElementById("responsenumberseven");
            if ((responsenumberseven.value == '' || responsenumberseven.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseEightFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbereight = document.getElementById("responsenumbereight");
            if ((responsenumbereight.value == '' || responsenumbereight.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },

        onlyResponseNineFormat($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
            var responsenumbernine = document.getElementById("responsenumbernine");
            if ((responsenumbernine.value == '' || responsenumbernine.length > 1) && (keyCode == 48)) {
                $event.preventDefault();
            }
        },
        clearresponse() {
            this.responsevalueone = '';
            this.responsestatusone = '';

            this.responsevaluetwo = '';
            this.responsestatustwo = '';

            this.responsevaluethree = '';
            this.responsestatusthree = '';

            this.responsevaluefour = '';
            this.responsestatusfour = '';

            this.responsevaluefive = '';
            this.responsestatusfive = '';

            this.responsevaluesix = '';
            this.responsestatussix = '';

            this.responsevalueseven = '';
            this.responsestatusseven = '';

            this.responsevalueeight = '';
            this.responsestatuseight = '';

            this.responsevaluenine = '';
            this.responsestatusnine = '';
            setTimeout(() => {
                this.v$.$reset();
            }, 0);
        },
        changeStatusOne(e) {
            // console.log(e);
            if (e == true) {
                this.responsestatusone = 1;
            } else if (e == false) {
                this.responsestatusone = 0;
            }
        }
    },
};
</script>
<style>
.campaign-modal-outer-border {
    padding: 15px;
    border: 1px solid #dee2e6;
    border-top: 0;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.intelligent-campagin-modal-header {
    font-family: 'AcuminPro-SemiBold';
    font-size: 16px;
    text-align: left;
    letter-spacing: 0.52px;
    color: #0B294B;
    line-height: 18px;
    margin-top: 10px;
}

.campaign-tab-outer-scroll {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    display: block;
}

.campaign-tab-outer-scroll::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.campaign-tab-outer-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.form-box-outer {
    padding: 16px 16px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}
</style>
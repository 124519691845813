<template>
  <div class="col-md-3 col-lg-2 header-navbar-outer">
    <div class="w-100">
      <div class="navbar-brand-fixed-outer">
        <header class="navbar navbar-dark bg-dark flex-md-nowrap">
          <div class="navbar-brand me-0">
            <!-- <div class="navbar-brand-image d-lg-flex d-block justify-content-between">
              <img src="/assets/images/political-icon.svg" class="rounded-circle dashboard-img-border" alt="client-logo"
                width="40" height="40" />
              <img v-if="this.client_info.maa6 == null || this.client_info.maa6 == ''"
                src="/assets/images/political-icon.svg" class="rounded-circle dashboard-img-border" alt="client-logo"
                width="40" height="40" />
              <img v-if="this.client_info.maa6 != null && this.client_info.maa6 != ''" :src="client_logo"
                class="rounded-circle dashboard-img-border" alt="client-logo" width="40" height="40" />
            </div>
             -->
            <!-- <div class="navbar-brand-label text-capitalize">{{ client_party }}</div> -->
            <div class="logo-outer">
            <img src="/assets/images/goa-electronics.png" class="img-fluid" alt="Goa Electronics Limited" />
          </div>
          </div>
          <button class="navbar-toggler d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </header>
      </div>
      <nav id="sidebarMenu" class="d-md-block collapse nav-navlink-outer">
        <div class="menubar-outer">
          <div v-for="(menuItem, headerIndex) in sidebarMenu " :key="headerIndex">
            <ul class="nav flex-column" :id="`header-${headerIndex}`">
              <li class="nav-item">
                <router-link v-if="menuItem.mainMenu" class="nav-link disabled-link" :to="menuItem.link || ''" >
                  <img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{ menuItem.mainMenu
                  }}</router-link>
                <!-- </li>
              <li class="nav-item" v-show="this.permission_fks.includes('2')"> -->
                <button v-else-if="menuItem.mainMenuHeader" class="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse" :data-bs-target="`#${menuItem.mainMenuHeader.replaceAll(' ', '-')}`"
                  aria-expanded="false" :disabled = "headerIndex != 3">
                  <span class="mr-auto"><img :src="menuItem.img" class="navlink-tab-icon" alt="" width="16" />{{
                    menuItem.mainMenuHeader }}</span>
                </button>
                <div v-if="menuItem.sidebarSubMenu" class="collapse" :data-bs-parent="`#header-${headerIndex}`"
                  :id= "menuItem.mainMenuHeader.replaceAll(' ', '-')">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-for="(subMenuItem, index) in menuItem.sidebarSubMenu" :key="index">
                      <!-- {{ subMenuItem.submenu }} -->
                      <router-link class="nav-link" :to="subMenuItem.link">{{ subMenuItem.submenu }}</router-link>
                    </li>
                    <!-- v-show="this.permission_fks.includes('3')" -->
                    <!-- <li v-show="this.permission_fks.includes('11')">
                      <router-link class="nav-link" to="/underprogress">DLT</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('4')">
                      <router-link class="nav-link" to="/underprogress">Templates</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('5')">
                      <router-link class="nav-link" to="/underprogress">Offices</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('52')">
                      <router-link class="nav-link" to="/settings/designation">Designation</router-link>
                    </li> -->
                  </ul>
                </div>
              </li>
              <!-- <li class="nav-item">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-voter-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/voters-menu-icon.svg" class="navlink-tab-icon" alt="" width="16" />Guests
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-voter-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <router-link class="nav-link" to="/visitors/addvisitor">Add Guest</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/visitors/listvisitors">List Guests</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('17')">
                <router-link class="nav-link" to="/unassignedvoters"><img
                    src="/assets/images/icon/unassigned-voters-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Unassigned Voters</router-link>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('18')">
                <router-link class="nav-link" to="/importvoter"><img src="/assets/images/icon/import-voters-menu-icon.svg"
                    class="navlink-tab-icon" alt="" width="16" />Import Voters</router-link>
              </li>
              <li class="nav-item">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-new-campaign-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/campaign-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Campaign
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-new-campaign-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <router-link class="nav-link" to="/campaign">Campaign Listing</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/campaigntemplatelist">Campaign Template</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-upcoming-users-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/customers-tab-icon.svg" class="navlink-tab-icon" alt="" width="16" />Users
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-upcoming-users-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <router-link class="nav-link" to="/user/add">Add User</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/user/listing">Users List</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/user/role">Permission Sets</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('25')">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-upcoming-karyakartas-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/users-tab-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Karyakartas
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-upcoming-karyakartas-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-show="this.permission_fks.includes('26')">
                      <router-link class="nav-link" to="/karyakartas/add">Add Karyakartas</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('27')">
                      <router-link class="nav-link" to="/karyakartas/list">List Karyakartas</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('28')">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-system-visitor-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/visitors-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Visitors
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-visitor-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-show="this.permission_fks.includes('29')">
                      <router-link class="nav-link" to="/visitor/add">Add Visitor</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('30')">
                      <router-link class="nav-link" to="/underprogress">Visits Done</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('31')">
                      <router-link class="nav-link" to="/underprogress">Visits Cancelled</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('32')">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-system-phone-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/cloud-telephony-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Cloud
                  Telephony
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-phone-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-show="this.permission_fks.includes('33')">
                      <router-link class="nav-link" to="/cloudtelephony/virtualnumbers">Virtual Numbers</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('34')">
                <router-link class="nav-link" to="/companydetails"><img src="/assets/images/icon/company-menu-icon.svg"
                    class="navlink-tab-icon" alt="" width="16" />Company Details</router-link>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('35')">
                <router-link class="nav-link" to="/tags"><img src="/assets/images/icon/voter-tags-menu-icon.svg"
                    class="navlink-tab-icon" alt="" width="16" />Voter Tags</router-link>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('36')">
                <router-link class="nav-link" to="/eventsettings/sms"><img
                    src="/assets/images/icon/event-settings-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Event Settings</router-link>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('37')">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-system-billing-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/billing-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Billing
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-system-billing-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-show="this.permission_fks.includes('38')">
                      <router-link class="nav-link" to="/billing/dashboard">Dashboard</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('39')">
                      <router-link class="nav-link" to="/billing/invoice">Tax Invoices</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('40')">
                      <router-link class="nav-link" to="/billing/statement">Account Statement</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('41')">
                      <router-link class="nav-link" to="/billing/receipt">Payment Receipts</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" v-show="this.permission_fks.includes('42')">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-support-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/support-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />Support
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-support-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li v-show="this.permission_fks.includes('43')">
                      <router-link class="nav-link" to="/support/create">Create Ticket</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('44')">
                      <router-link class="nav-link" to="/support/new">New Tickets</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('44')">
                      <router-link class="nav-link" to="/support/open">Open Tickets</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('44')">
                      <router-link class="nav-link" to="/support/cancelled">Cancelled Tickets</router-link>
                    </li>
                    <li v-show="this.permission_fks.includes('45')">
                      <router-link class="nav-link" to="/support/closed">Closed Tickets</router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <button class="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse"
                  data-bs-target="#nav-logs-collapse" aria-expanded="false">
                  <img src="/assets/images/icon/system-logs-menu-icon.svg" class="navlink-tab-icon" alt=""
                    width="16" />System Logs
                </button>
                <div class="collapse" data-bs-parent="#accordionNavLinkParent" id="nav-logs-collapse">
                  <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <router-link class="nav-link" to="/logs/sms">SMS Logs</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/logs/whatsapplogs">WhatsApp Logs</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/logs/email">Email Logs</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/logs/incoming">Incoming Logs</router-link>
                    </li>
                    <li>
                      <router-link class="nav-link" to="/logs/outgoing">Outgoing Logs</router-link>
                    </li>
                  </ul>
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </nav>
      <div class="copyright-outer mt-auto">
        <div class="copyright-sub-inner p-2">
          <div class="logo-outer">
            <img src="/assets/images/Visitor-Connect-Logo-New.png" class="img-fluid" alt="Visitor Connect" width="90" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../service/ApiService";
export default {
  data() {
    return {
      client_name: "",
      client_party: "",
      client_info: "",
      client_logo: "",
      permission_fks: localStorage.permission_fks,
      sidebarMenu: [
        {
          mainMenu: "Dashboard",
          link: "/dashboard",
          img: "/assets/images/icon/overview-tab-icon.svg",
        },
        {
          mainMenuHeader: "Users",
          img: "/assets/images/icon/customers-tab-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Add User",
              link: "/user/add"
            },
            {
              submenu: "List Users",
              link: "/user/listing"
            },
            {
              submenu: "Permission Sets",
              link: "/user/permissionsets"
            }

          ]
        },
        {
          mainMenuHeader: "Host",
          img: "/assets/images/icon/customers-tab-icon.svg",
          sidebarSubMenu: [
            // {
            //   submenu: "Add Host",
            //   link: "/hosts/add"
            // },

            {
              submenu: "List Host",
              link: "/hosts/listing"
            }
          ]
        },
        {
          mainMenuHeader: "Guests",
          img: "/assets/images/icon/customers-tab-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Total Guests",
              link: "/guests/totalguest"
            },
            {
              submenu: "Reports",
              link: "/guests/reports"
            },
            {
              submenu: "Guests Reported By Host/Police",
              link: "/guests/reportedby"
            },
          ]
        },
        {
          mainMenuHeader: "Law Users",
          img: "/assets/images/icon/customers-tab-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Add User",
              link: "/lawuser/add"
            },
            {
              submenu: "List Users",
              link: "/lawuser/listing"
            }
          ]
        },
        {
          mainMenuHeader: "Campaign",
          img: "/assets/images/icon/campaign-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Campaign List",
              link: "/campaign"
            },
            {
              submenu: "Campaign Template",
              link: "/campaigntemplate/whatsapp"
            },

          ]
        },
        {
          mainMenuHeader: "Cloud Telephony",
          img: "/assets/images/icon/cloud-telephony-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Virtual Numbers",
              link: "/cloudtelephony/virtualnumbers"
            },
            {
              submenu: "Custom Tunes",
              link: "/cloudtelephony/custometunes"
            },

          ]
        },
        {
          mainMenuHeader: "Host Billing",
          img: "/assets/images/icon/billing-menu-icon.svg",
          sidebarSubMenu: [
        
            {
              submenu: "Dashboard",
              link: "/billing/dashboard"
            },
            {
              submenu: "Tax Invoices",
              link: "/billing/invoice"
            },
            {
              submenu: "Account Statement",
              link: "/billing/statement"
            },
            {
              submenu: "Payment Receipts",
              link: "/billing/receipt"
            },
            {
              submenu: "Live Balances",
              link: "/billing/livebalances"
            },

          ]
        },
        {
          mainMenuHeader: "Super Admin Billing",
          img: "/assets/images/icon/billing-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Dashboard",
              link: "/admin/billing/dashboard"
            },
            {
              submenu: "Tax Invoices",
              link: "/admin/billing/invoice"
            },
            {
              submenu: "Account Statement",
              link: "/admin/billing/statement"
            },
            {
              submenu: "Payment Receipts",
              link: "/admin/billing/receipt"
            },
            {
              submenu: "Company Details",
              link: "/admin/billing/companydetails"
            }
          ]
        },
        {
          mainMenuHeader: "System Logs",
          img: "/assets/images/icon/system-logs-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Sms Logs",
              link: "/logs/sms"
            },
            {
              submenu: "Whatsapp Logs",
              link: "/logs/whatsapplogs"
            },
            {
              submenu: "Web Bot Logs",
              link: "/logs/webbotlogs"
            },
            {
              submenu: "Email Logs",
              link: "/logs/email"
            },
            {
              submenu: "Incomming Call Logs",
              link: "/logs/incoming"
            },
            {
              submenu: "Outgoing Call Logs",
              link: "/logs/outgoing"
            },
            {
              submenu: "Aadhar Logs",
              link: "/logs/aadhar-v1-card"
            },
            {
              submenu: "DL Logs",
              link: "/logs/driving-licence-logs"
            },
            {
              submenu: "PassPort Logs",
              link: "/logs/passportlogs"
            },
          ]
        },
        {
          mainMenu: "Support Tickets",
          link: "/support/new",
          img: "/assets/images/icon/support-menu-icon.svg",
        },
        {
          mainMenuHeader: "Settings",
          img: "/assets/images/icon/setting-menu-icon.svg",
          sidebarSubMenu: [
            {
              submenu: "Charges Settings",
              link: "/settings/chargessetting"
            },
            {
              submenu: "Guests Record Settings",
              link: "/settings/guestrecordsetting"
            },
            {
              submenu: "Host Settings",
              link: "/settings/hostsetting"
            },
            {
              submenu: "Invoice Settings",
              link: "/settings/invoice"
            }
          ]
        },
      ]
    };
  },
  mounted() {
    // if (this.permission_fks) {
    //   this.permission_fks = this.permission_fks.split(',');
    // } else {
    //   this.permission_fks = [];
    // }
    this.getModules();
    this.client_info = JSON.parse(localStorage.client_info);
    // this.client_name = this.client_info
    this.client_party = this.client_info.maa4;
    this.client_path = this.client_info.maa24;
    this.client_logo = "https://storage.googleapis.com/" + this.client_path + "/" + this.client_info.maa6;
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  methods: {
    getModules(e) {
      this.ApiService.getModules(e).then((data) => {
        if (data.status == 200) {
          this.moduleList = data.parentModules;
        } else {
          this.moduleList = [];
        }
      });
    },
  }
};
</script>


<style>
.disabled-link {
  pointer-events: none; /* Prevents clicking */
}
</style>

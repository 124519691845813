import axios from "axios";
//axios.defaults.baseURL = "http://127.0.0.1:8000";
// axios.defaults.baseURL = "http://edynamicsadmin.in";
// const StorageObjUserRef = localStorage.user_reference;
// const StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : '';
// const client = StorageObjClient.maa1 ? StorageObjClient.maa1 : '';
// const user = localStorage.user;

export default class ApiService {
  StorageObjUserRef = localStorage.user_reference;
  StorageObjClient = localStorage.client_info ? JSON.parse(localStorage.client_info) : "";
  client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : "";
  user = localStorage.user;

  HeaderParam = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "content-type": "application/json",
    Accept: "application/json",
  };

  AuthenticatedUserHeader = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + this.StorageObjUserRef,
    client: this.client,
    user: this.user,
    user_login_branch: localStorage.user_login_branch
  };

  commonapiall = (url, params, method, type, item) => {
    return axios(url, {
      method: method,
      headers: params,
      data: item,
    })
      .then((response) => {
        if (response.data.status === 403) {
          this.logout();
        }
        // else if (response.data.status === 401 ) {
        //  this.logoutonsessiontimeout({ logout_reason: response.data.logout_reason });

        // localStorage['logout_reason'] = response.data.message;
        // localStorage['error_toaster_display'] = 1;
        //location.reload();
        // this.logoutsession();
        // }
        else {
          return response.data;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
  // admin user APIs
  login(item) {
    let URL = "/api/u/login";
    this.HeaderParam["client"] = this.client;
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  logoutsession(item) {
    let URL = "/api/u/logoutsession";
    return axios(URL, {
      method: "POST",
      headers: this.AuthenticatedUserHeader,
      data: item,
    })
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  forgotpassword(item) {
    let URL = "/api/u/forgotpassword";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getLoginUserByMobileNumber(item) {
    let url = "/api/u/getLoginUserByMobileNumber";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  //Common Controller
  getClientByDomain(item) {
    let URL = "/api/getClientByDomain";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  // User Controller
  resetPassword(item) {
    let URL = "/api/v1/resetPassword";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  logout(item) {
    localStorage.clear();
    let URL = "/api/v1/logout";
    return axios(URL, {
      method: "POST",
      headers: this.AuthenticatedUserHeader,
      data: item,
    })
      .then((response) => {
        localStorage.clear();
        window.location.href = "/";
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  getUserList(item) {
    let url = "/api/v1/getUserList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getRoleList(item) {
    let url = "/api/v1/getRoleList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getRoles(item) {
    let url = "/api/v1/getRoles";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateUserProfile(item) {
    let url = "/api/v1/updateUserProfile";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addOrUpdateUser(item) {
    let url = "/api/v1/addOrUpdateUser";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateUserPermissions(item) {
    let url = "/api/v1/updateUserPermissions";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateuserallowpermissions(item) {
    let url = "/api/v1/updateuserallowpermissions";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserById(item) {
    let url = "/api/v1/getUserById";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserUsingMobileNumber(item) {
    let URL = "/api/v1/getUserUsingMobileNumber";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  changeUserLockStatus(item) {
    let url = "/api/v1/changeUserLockStatus";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getActiveUsers(item) {
    let url = "/api/v1/getActiveUsers";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  updaterolestatus(item) {
    let url = "/api/v1/updaterolestatus";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  // Host Controller
  addOrUpdateHost(item) {
    let url = "/api/v1/addOrUpdateHost";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostList(item) {
    let url = "/api/v1/getHostList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostDetailsById(item) {
    let url = "/api/v1/getHostDetailsById";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostListValues(item) {
    let url = "/api/v1/getHostListValues";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserListByHostId(item) {
    let url = "/api/v1/getUserListByHostId";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }

  getHostBranchesByHostId(item) {
    let url = "/api/v1/getHostBranchesByHostId";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getBranchDetailsByBranchId(item) {
    let url = "/api/v1/getBranchDetailsByBranchId";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addOrUpdateHostBranch(item) {
    let url = "/api/v1/addOrUpdateHostBranch";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAdminUsers(item) {
    let url = "/api/v1/getAdminUsers";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }

  //   VisitorController
  addVisitor(item) {
    let url = "/api/v1/addVisitor";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  updateVisitor(item) {
    let url = "/api/v1/updateVisitor";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getHostVisitorHistoryList(item) {
    let url = "/api/v1/getHostVisitorHistoryList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getAllVisitors(item) {
    let url = "/api/v1/getAllVisitors";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getvisitorusingcontact(item) {
    let url = "/api/v1/getvisitorusingcontact";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  generateaadharotp(item) {
    let URL = "/api/v1/generateaadharotp";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getaadharcarddetails(item) {
    let URL = "/api/v1/getaadharcarddetails";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getaadhardetails(item) {
    let URL = "/api/v1/getaadhardetails";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  updateVisitorVisitingInfo(item) {
    let URL = "/api/v1/updateVisitorVisitingInfo";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getlicensedetails(item) {
    let URL = "/api/v1/getlicensedetails";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getVisitorHistroyByVisitorId(item) {
    let url = "/api/v1/getVisitorHistroyByVisitorId";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addForeignerVisitor(item) {
    let URL = "/api/v1/addForeignerVisitor";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getPassportdetails(item) {
    let URL = "/api/v1/getPassportdetails";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  updateHostVerifyStatus(item) {
    let URL = "/api/v1/updateHostVerifyStatus";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getVisitorReports(item) {
    let URL = "/api/v1/getVisitorReports";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);

  }
  
  //   Common Controller

  getstates(item) {
    let URL = "/api/v1/getstates";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcities(item) {
    let URL = "/api/v1/getcities";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getareas(item) {
    let URL = "/api/v1/getareas";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcompanytypes(item) {
    let URL = "/api/v1/getcompanytypes";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getUserDesignations(item) {
    let url = "/api/v1/getUserDesignations";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getModules(item) {
    let URL = "/api/v1/getModules";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostBranches(item) {
    let url = "/api/v1/getHostBranches";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }

  getCategories(item) {
    let URL = "/api/v1/getCategories";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getalltitle(item) {
    let URL = "/api/getalltitle";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  outgoingcall(item) {
    let URL = "/api/v1/outgoingcall";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCountryCode(item) {
    let URL = "/api/v1/getCountryCode";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCountryList(item) {
    let URL = "/api/v1/getCountryList";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getVisitingReason(item) {
    let URL = "/api/v1/getVisitingReason";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getweekdays(item) {
    let URL = "/api/v1/getweekdays";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getlanguages(item) {
    let URL = "/api/v1/getlanguages";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }

  // LogController

  getTransactionalEmailLogs(item) {
    let url = "/api/v1/getTransactionalEmailLogs";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getTransactionalSmsLogs(item) {
    let url = "/api/v1/getTransactionalSmsLogs";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getIncomingCallLogs(item) {
    let url = "/api/v1/getIncomingCallLogs";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getOutgoingCallLogs(item) {
    let url = "/api/v1/getOutgoingCallLogs";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getRcsLogs(item) {
    let url = "/api/v1/getRcsLogs";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getShortUrlLogs(item) {
    let url = "/api/v1/getShortUrlLogs";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsapplogs(item) {
    let URL = "/api/v1/getwhatsapplogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAadharApiLogs(item) {
    let URL = "/api/v1/getAadharApiLogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getDrivingLicenseApiLogs(item) {
    let URL = "/api/v1/getDrivingLicenseApiLogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getPassportApiLogs(item) {
    let URL = "/api/v1/getPassportApiLogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getWebBotLogs(item) {
    let URL = "/api/v1/getWebBotLogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getWebBotchatlogs(item) {
    let URL = "/api/v1/getWebBotchatlogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  // Billing Controller
  getHostAccountStatement(item) {
    let url = "/api/v1/getHostAccountStatement";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getHostPaymentReceipt(item) {
    let url = "/api/v1/getHostPaymentReceipt";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getHostTaxInvoices(item) {
    let URL = "/api/v1/getHostTaxInvoices";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostCompanyList(item) {
    let URL = "/api/v1/getHostCompanyList";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  addorUpdateHostCompany(item) {
    let URL = "/api/v1/addorUpdateHostCompany";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getChargesSettings(item) {
    let URL = "/api/v1/getChargesSettings";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  addorUpdateChargesSettings(item) {
    let URL = "/api/v1/addorUpdateChargesSettings";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  changeBillingCompanyStatus(item) {
    let URL = "/api/v1/changeBillingCompanyStatus";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getHostBillingDashboardData(item) {
    let URL = "/api/v1/getHostBillingDashboardData";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAccountStatement(item) {
    let URL = "/api/v1/getAccountStatement";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getClientPaymentReceipt(item) {
    let URL = "/api/v1/getClientPaymentReceipt";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getClientTaxInvoices(item) {
    let URL = "/api/v1/getClientTaxInvoices";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  addFunds(item) {
    let URL = "/api/v1/addFunds";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getbanklist(item) {
    let URL = "/api/v1/getbanklist";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getAdminCompanyList(item) {
    let URL = "/api/v1/getAdminCompanyList";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  addorUpdateAdminCompany(item) {
    let URL = "/api/v1/addorUpdateAdminCompany";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getBillingDashboardData(item) {
    let URL = "/api/v1/getBillingDashboardData";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getCurrentPlanDetails(item) {
    let URL = "/api/v1/getCurrentPlanDetails";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getTaxInvoiceComponent(item) {
    let URL = "/api/v1/getTaxInvoiceComponent";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  changeAdminBillingCompanyStatus(item) {
    let URL = "/api/v1/changeAdminBillingCompanyStatus";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  //Campaign Controller
  getcampaigntemplatesList(item) {
    let URL = "/api/v1/getcampaigntemplatesList";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  createWhatsappBulkCampaign(item) {
    let URL = "/api/v1/createWhatsappBulkCampaign";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  createCallsBulkCampaign(item) {
    let URL = "/api/v1/createCallsBulkCampaign";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcampaign(item) {
    let URL = "/api/v1/getcampaign";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getcampaignlogs(item) {
    let URL = "/api/v1/getcampaignlogs";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  campaignreport(item) {
    let URL = "/api/v1/campaignreport";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  campaigntemplates(item) {
    let URL = "/api/v1/campaigntemplates";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "get", 0, item);
  }
  savecampaign(item) {
    let URL = "/api/v1/savecampaign";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  storecampaigntemplate(item) {
    let URL = "/api/v1/storecampaigntemplate";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  getCampaignFilterList(item) {
    let URL = "/api/v1/getCampaignFilterList";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "Post", 0, item);
  }
  addScheduleCampaign(item) {
    let url = "/api/v1/addScheduleCampaign";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsappcampaigntemplates(item) {
    let url = "/api/v1/getwhatsappcampaigntemplates";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addWhatsappCampaignTemplate(item) {
    let url = "/api/v1/addWhatsappCampaignTemplate";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getwhatsappcampaigntemplateList(item) {
    let url = "/api/v1/getwhatsappcampaigntemplateList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcallcampaigntemplateList(item) {
    let url = "/api/v1/getcallcampaigntemplateList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addCallCampaignTemplate(item) {
    let url = "/api/v1/addCallCampaignTemplate";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcallcampaigntemplates(item) {
    let url = "/api/v1/getcallcampaigntemplates";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getnotificationcampaigntemplates(item) {
    let url = "/api/v1/getnotificationcampaigntemplates";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addnotificationTemplate(item) {
    let url = "/api/v1/addnotificationTemplate";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getnotificationtemplateList(item) {
    let url = "/api/v1/getnotificationtemplateList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  //Cloudtelephony Controller
  getVirtualNumbersList(item) {
    let url = "/api/v1/getvirtualnumberslist";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getCtSettingConfig(item) {
    let url = "/api/v1/getctsettingconfig";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcommonctandctholdtunes(item) {
    let URL = "/api/v1/getcommonctandctholdtunes";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getdefaultctandctholdtunes(item) {
    let URL = "/api/v1/getdefaultctandctholdtunes";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  savemobilenumber(item) {
    let url = "/api/v1/savemobilenumber";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  addupdatesetting(item) {
    let URL = "/api/v1/addupdatesetting";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  updatetunesconfig(item) {
    let URL = "/api/v1/updatetunesconfig";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  otherdays(item) {
    let URL = "/api/v1/otherdays";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  copysettonewday(item) {
    let URL = "/api/v1/copysettonewday";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  deletesettingset(item) {
    let URL = "/api/v1/deletesettingset";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getcttunes(item) {
    let URL = "/api/v1/getcttunes";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getctholdtunes(item) {
    let URL = "/api/v1/getctholdtunes";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  savetunesconfig(item) {
    let URL = "/api/v1/savetunesconfig";
    return this.commonapiall(URL, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  checkweekdayes(item) {
    let url = "/api/v1/checkweekdayes";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getcttuneslist(item) {
    let url = "/api/v1/getcttuneslist";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  addcustomtunes(item) {
    let url = "/api/v1/addcustomtunes";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  updatecustomtunes(item) {
    let url = "/api/v1/updatecustomtunes";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  cttunesStatusUpdate(item) {
    let url = "/api/v1/cttunesStatusUpdate";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  saveExistingVoterSetting(item) {
    let url = "/api/v1/saveExistingVoterSetting";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getExistingSettings(item) {
    let url = "/api/v1/getExistingSettings";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getclientuser(item) {
    let url = "/api/v1/getclientuser";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  // TicketController
  createTicket(item) {
    let url = "/api/v1/createTicket";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getTicketsByStatus(item) {
    let url = "/api/v1/getTicketsByStatus";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 0, item);
  }
  getTicketMessages(item) {
    let url = "/api/v1/getTicketMessages";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  ticketCommunication(item) {
    let url = "/api/v1/ticketCommunication";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  cancelTicket(item) {
    let url = "/api/v1/cancelTicket";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  // TemplateSettingsController
  sendtemplatealerts(item) {
    let url = "/api/v1/sendtemplatealerts";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  // SettingsController
  updateHostApprovalSetting(item) {
    let url = "/api/v1/updateHostApprovalSetting";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  updateInvoiceGenerationSetting(item) {
    let url = "/api/v1/updateInvoiceGenerationSetting";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  reportVisitor(item){
    let url = "/api/v1/reportVisitor";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getReportReasonsList(item){
    let url = "/api/v1/getReportReasonsList";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getVisitorReportsHistory(item){
    let url = "/api/v1/getVisitorReportsHistory";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  changeHostBranchStatus(item){
    let url = "/api/v1/changeHostBranchStatus";
    return this.commonapiall(url, this.AuthenticatedUserHeader, "POST", 1, item);
  }
  getVisitorWebBotHistory(item) {
      let url = '/api/u/getVisitorWebBotHistory';
      return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  webincomingmsg(item) {
    let url = '/api/u/webincomingmsg';
      return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  verifyMobileNumber(item) {
      let url = '/api/u/verifyMobileNumber';
      return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  verifyOtp(item) {
      let url = '/api/u/verifyOtp';
      return this.commonapiall(url, this.HeaderParam, 'Post', 0, item);
  }
  getCountryListBot(item) {
    let URL = "/api/u/getCountryList";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
  getWebCountryCode(item) {
    let URL = "/api/getCountryCode";
    return this.commonapiall(URL, this.HeaderParam, "POST", 0, item);
  }
}


